module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {


if (!cnTitle) var cnTitle = '通用标题';
if (!enTitle) var enTitle = 'common title';
;
__p += '\r\n<div class="common-title">\r\n    <div class="cn-title"><span>' +
((__t = ( cnTitle )) == null ? '' : __t) +
'</span></div>\r\n    <div class="en-title">' +
((__t = ( enTitle )) == null ? '' : __t) +
'</div>\r\n</div>\r\n';

}
return __p
}