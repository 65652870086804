import './index.scss'
import Swiper from 'swiper/js/swiper.min.js'

// 点击打开移动端导航栏
$('.root-header [data-open="cmenu"]').on('click', function () {
    if ($('.root-header .hamburger').hasClass('active')) {
      $('.root-header .hamburger').removeClass('active')
      $('.root-header .nav-mob').removeClass('active')
    } else {
      $('.root-header .hamburger').addClass('active')
      $('.root-header .nav-mob').addClass('active')
    }
})
//点击移动端导航列表展开子项
$('.root-header .nav-mob ul li').on('click',function () {
    if(!$(this).hasClass('active')){
        $(this).addClass('active').siblings().removeClass('active')
        $(this).find('.subnav').slideDown(400)
        $(this).siblings().find('.subnav').slideUp(400)
    }
})

$(".root-header .nav-pc ul li").mouseenter(function () {
  if(!$(this).hasClass('active')){
    $(this).children('a').find('span b').css('width', '100%');
    $(this).siblings().children('a').find('span b').css('width', '0');
  }
});
$(".root-header .nav-pc ul li").mouseleave(function () {
  if (!$(this).hasClass('active')) {
    $('.root-header .nav-pc ul li').children('a').find('span b').css('width', '0');
    $('.root-header .nav-pc ul li.active').children('a').find('span b').css('width', '100%');
  }
});

//页面滚动监听
var beforeScroH = $(document).scrollTop();
$(document).scroll(function () {
  var scroH = $(document).scrollTop(); //滚动高度
  var viewH = $(window).height(); //可见高度
  var contentH = $(document).height(); //内容高度

  //判断页面是上滚还是下滚
  var delta = scroH - beforeScroH;
  if (delta > 0) {
    if (scroH > 1000){
      $('.root-header').addClass('hide')
    }
  } else if (delta < 0) {
    $('.root-header').removeClass('hide')
  }
  beforeScroH = scroH;

  if (scroH > 50) { //距离顶部大于140px时
    $('.root-header').addClass('scroll')
  }else{
    $('.root-header').removeClass('scroll')
  }
  if (contentH - (scroH + viewH) <= 100) { //距离底部高度小于100px

  }
  if (contentH = (scroH + viewH)) { //滚动条滑到底部啦

  }

});

